.modal {
  color: var(--secondary);
  width: 500px;
}

.content {
  display: flex;
  flex-direction: column;
  color: #7a6e8a;
}

.description {
  color: #7a6e8a;
  margin: 0 0 8px 0;
}

.recommendedSize {
  font-size: 14px;
  font-weight: 400;
  margin: 0 0 8px 0;
}

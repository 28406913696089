*,
*::before,
*::after {
  box-sizing: border-box;
}

:root {
  --font-family: "Open Sans", "sans-serif";

  --background-gray: rgba(238, 238, 238, 0.753);
  --background-color: #f8f6fc;
  --border-color: #dce1e6;
  --link-color: #007bff;

  --text-color-default: #212529;

  --text-color: #7a6e8a;
  --text-color-light: #8b8098;
  --text-color-hover: #684e88;
  --text-color-disabled: #c7c2cf;

  --title-color: #1b0a33;
}

body {
  margin: 0;
  font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.ReactModal__Body--open {
  overflow: hidden;
  position: fixed;
  width: 100%;
  height: 100%;
}

button {
  font-family: var(--font-family);
}

.modal {
  width: calc(100% - 40px);
  padding: 20px 40px;
  @media (min-width: 1024px) {
    width: 510px !important;
    height: 620px;
    padding: 20px 20px 40px 20px;
    transition: 0.5s;
    &.sent {
      height: 375px;
    }
  }
}

.sentIcon {
  height: 54px;
  width: 94px;
  height: auto;
  margin: 0 auto 45px auto;
  display: block;
}

.messageTitle {
  color: var(--secondary);
  font-size: 24px;
  font-weight: 600;
  line-height: 14px;
  text-align: center;
  margin-bottom: 30px;
}

.messageText {
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 20px;
}

.finishButton {
  height: 40px;
  width: 220px;
  display: block;
  margin: 0 auto;
  text-transform: uppercase;
}

.page {
  display: flex;
  flex-direction: column;
}

.pageBlock {
  display: flex;
  flex-direction: column;
}

.pageBlockTopbar {
  margin-bottom: 20px;
  min-height: 56px;
}

.pageTitle {
  color: var(--secondary);
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  text-transform: uppercase;
}

.content {
  display: grid;
  grid-template-columns: 1fr 4fr;
  gap: 40px;
  height: 100%;
  padding: 60px 40px;
}

.themePreview {
  flex: 1;
}

.button {
  background: rgba(146, 79, 239, 0.1);
  border: 1px dashed var(--secondary);
  border-radius: 10px;
  padding: 13px 0;
  width: 100%;
  color: var(--secondary);
  cursor: pointer;
  font-size: 12px;
  line-height: 16px;
  font-weight: 600;
  text-align: center;
}

.uploadButtons .button:not(:first-child) {
  margin-top: 20px;
}

.livePreviewLink {
  margin: 30px 0 0 0;
  display: flex;
  justify-content: center;
}

.link {
  background: var(--primary);
  border-radius: 10px;
  padding: 13px 20px;
  font-weight: 700;
  font-size: 14px;
  line-height: 14px;
  color: #ffffff;
  text-decoration: none;

  &:active {
    opacity: 0.8;
  }

  &:active,
  &:hover {
    background: var(--primaryHover);
    cursor: pointer;
  }
}

.themeSelector {
  margin: 20px 0;
}

.title {
  color: var(--secondary);
  font-size: 18px;
  font-weight: 600;
  margin: 0 0 8px 0;
}

.text {
  color: #7a6e8a;
  font-size: 14px;
  font-weight: 300;
  line-height: 19px;
  margin: 0;
}

.themeColorsPreview {
  margin: 20px 0;
}

.preview {
  display: flex;
  flex-direction: column;
}

.color {
  display: flex;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: 0.5rem;
  }
}

.colorName {
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #7a6e8a;
  flex: 1;
}

.colorDetails {
  display: flex;
  flex: 1;
}

.colorBox {
  border: none;
  cursor: pointer;
  height: 19px;
  width: 19px;
  margin-right: 4px;
}

.colorValue {
  font-size: 14px;
  line-height: 19px;
  color: #7a6e8a;
}

.colorDropdownContent {
  padding: 15px;
}

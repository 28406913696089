.themePreview {
  display: grid;
  grid-template-columns: 1fr 4fr;
  grid-template-areas:
    "topbarLogo topbarContent"
    "sidebar content";
  grid-template-rows: 80px 1fr;
  filter: drop-shadow(0px 4px 30px rgba(0, 0, 0, 0.15));
  background-color: #ffffff;
  border-radius: 10px;
  overflow: hidden;
  height: 100%;
}

.topbarLogo {
  grid-area: topbarLogo;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.topbarLogo__Image {
  max-width: 100px;
}

.topbarContent {
  grid-area: topbarContent;
  background-color: #fff;
}

.topbarContent__Content {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #dce1e6;
  margin-left: 50px;
  padding: 1rem 0 1rem 0;
}

.topbarContentPageTitle {
  font-size: 20px;
  line-height: 1.5;
  font-weight: 600;
  color: var(--secondary);
}

.topbarContentUser {
  display: flex;
  align-items: center;
  margin-left: auto;
  padding-right: 20px;
}

.topbarContentUserDetailsName {
  font-size: 16px;
  font-weight: 800;
  line-height: 22px;
  color: var(--secondary);
  margin: 0;
}

.topbarContentUserDetailsRole {
  font-size: 14px;
  font-weight: 300;
  line-height: 20px;
  color: #7a6e8a;
}

.topbarContentUserAvatar {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  margin-left: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.topbarContentUserAvatar__Image {
  max-width: 100%;
}

.sidebar {
  grid-area: sidebar;
  background-color: var(--secondary);
  background: linear-gradient(
    180deg,
    var(--secondaryLight),
    var(--secondary) 80%
  );
  border-top-right-radius: 15px;
  display: flex;
  flex-direction: column;
  min-width: 185px;
}

.content {
  grid-area: content;
  background-color: #ffffff;
}

.sidebarMenu {
  display: flex;
  flex-direction: column;
  padding: 0;
  padding-top: 18px;
  list-style: none;
  flex: 1;
}

.sidebarMenuItem {
  color: #ffffff;
  margin-left: 20px;
  padding: 8px 10px 8px 16px;
  font-size: 14px;
  line-height: 16px;
  font-weight: 400;
  position: relative;
  cursor: pointer;
  border-top-left-radius: 100px;
  border-bottom-left-radius: 100px;

  &:hover {
    font-weight: 500;
  }

  &:not(:last-child) {
    margin-bottom: 1rem;
  }

  &Logout {
    margin-top: auto;
    padding: 0.5rem 2rem;
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 4px;
    align-self: center;
    margin-left: 0;

    &:hover {
      background-color: rgba(0, 0, 0, 0.4);
    }
  }

  &Active {
    background-color: #ffffff;
    color: var(--secondary);
    padding: 12px 10px 12px 15px;
    transform-style: preserve-3d;

    &:hover {
      font-weight: 400;
    }

    &::before {
      content: "";
      position: absolute;
      right: 0;
      height: 40px;
      width: 40px;
      top: -40px;
      border-bottom-right-radius: 15px;
      box-shadow: 0 20px 0 0 #fff;
    }

    &::after {
      content: "";
      position: absolute;
      right: 0;
      height: 40px;
      width: 40px;
      bottom: -40px;
      top: 40px;
      border-top-right-radius: 15px;
      box-shadow: 0 -20px 0 0 #fff;
    }
  }
}

.ranks {
  padding: 0 20px 40px;
  display: flex;
  flex-direction: column;
}

.rankCourse {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  background-color: #ffffff;
  border-radius: 10px;
  cursor: pointer;
}

.rankCourseName {
  color: var(--secondary);
  font-size: 16px;
  line-height: 1;
  font-weight: 600;
  text-overflow: ellipsis;
  width: 100%;
}

.rankCourseProgress {
  margin-top: auto;
  color: var(--primary);
  font-size: 12px;
  font-weight: 600;
  line-height: 12px;
}

.rankCourseProgressBar {
  margin-top: 0.5rem;
}

.rankCourses {
  grid-template-columns: initial !important;
  grid-auto-columns: initial !important;
}

.feedback {
  margin: 0 0 20px 20px;
}

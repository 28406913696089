.page {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: auto;
}

.topbar {
  display: flex;
  align-items: center;
  width: 100%;
}

.pageTitle {
  color: var(--secondary);
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  text-transform: uppercase;
}

.pageTitleLink {
  color: var(--secondary);
  text-decoration: none;
  margin-right: 20px;
}

.pageTitleApplyDesignText {
  margin-left: auto;
  margin-right: 12px;
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  text-transform: capitalize;
  color: #ffffff;
}

.button {
  font-weight: 700;
  font-size: 1.5em;
  line-height: 18px;
  color: #ffffff;
  text-transform: uppercase;
  width: 250px;
}

.content {
  display: flex;
  flex-direction: column;
  padding: 20px 38px;
  height: 100%;
}

.tabList {
  margin: 0 !important;
}

.tab {
  padding: 12px 56px;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 14px !important;
  border-radius: 10px 10px 0px 0px !important;
  border-top: 1px solid var(--secondaryLight) !important;
  border-right: 1px solid var(--secondaryLight) !important;
  border-left: 1px solid var(--secondaryLight) !important;

  &:not(:last-child) {
    margin-right: -1px !important;
  }

  &[aria-selected="true"] {
    background-color: var(--secondary) !important;
    color: #ffffff !important;
  }

  &:not([aria-selected="true"]) {
    background: #ffffff !important;
    color: #000000 !important;

    &:hover {
      color: var(--secondary) !important;
    }
  }
}

.tabPanels {
  height: 100%;
}

.tabPanel {
  height: 100%;
  border: 1px solid var(--secondaryLight);
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  overflow: auto;
}

.preview {
  height: 100% !important;
}

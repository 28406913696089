.livePreview {
  background-color: #ffffff;
  width: 100%;
  height: 100%;
}

.iframe {
  width: 100%;
  height: 100%;

  &Hidden {
    opacity: 0;
  }
}

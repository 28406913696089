.input {
  margin-bottom: 15px !important;
  display: block;
}

.subdomainWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-items: center;
  span {
    margin-left: 8px;
    color: #7a6e8a;
    font-weight: 600;
  }
  .validIcon, .invalidIcon, .spinner {
    width: auto;
    height: 17px;
    margin-left: 5px;
  }
  .spinner {
    position: relative;
    width: 17px;
    left: 0;
    right: 0;
    transform: translate(0);
    & > div > div {
      width: 17px;
      height: 17px;
      border-width: 3px;
      top: 0;
      left: 0;
      right: 0;
    }
  }
  .validIcon {
    color: #008000;
  }
  .invalidIcon {
    color: #ff0000;
  }
}

.sendButton {
  padding: 12px 30px !important;
  margin: 60px auto 0 auto;
  display: block;
}

.terms {
  display: block;
  margin-bottom: 20px;
  font-size: 12px;
  a {
    color: var(--secondary);
  }
}

.themeSelector {
  display: flex;
  flex-direction: column;
}

.label {
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #7a6e8a;
  display: block;
  margin: 0 0 8px 0;
}

.select {
  display: block;
  padding: 14px 20px 14px 20px;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  border-radius: 10px;
  border: 1px solid rgba(122, 110, 138, 0.15);
  background-color: #ffffff;
  background-repeat: no-repeat;
  background-position: right 6px center;
  background-size: 1.5rem 1.5rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%23dce1e6' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
  color: #7a6e8a;
  cursor: pointer;
  appearance: none;
  width: 100%;
}

.imageEditor {
  display: flex;
  flex-direction: column;
}

.content {
  display: flex;
  gap: 1rem;
  margin: 1rem 0 0.5rem;
  max-width: 100%;
}

.helpText {
  color: #7a6e8a;
  font-size: 14px;
  font-weight: 400;
  margin: 0 0 8px 0;
}

.imageFileDropzone {
  border: 3px dotted #ddd9fa;
  border-radius: 10px;
  padding: 1rem;
  margin: 0.5rem 0;
  cursor: pointer;
}

.imageFileDropzoneText {
  text-align: center;
  font-size: 0.9rem;
  font-weight: 500;
}

.editor {
  width: 100%;
}

.imageWrapper {
  width: 100%;
}

.imageWrapper > img {
  display: block;
  max-height: 200px;
  max-width: 100%;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.button {
  width: 150px;
  margin-top: 1rem;
}
